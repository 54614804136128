.fixedContact {
  margin-bottom: 0.5em;
  color: #222222;
  font-size: .75em;
}
.fixedContact > li:last-child {padding-right: 10px; font-size: 1.2em;}
.probootstrapHeader {
  padding-top: 20px;
  padding-bottom: 20px;
  
}

@media (max-device-width: 767px) {
  .fixedContact {
    text-align: left;
  }
  .fixedContact > li:first-child {padding-left: 10px;}
}