.filterLabels{
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid rgb(162,162,162);
    cursor: pointer;
}
.filterLabels:hover{
    background-color: rgb(162,162,162);
    color: #fff;
    border-left: 1px solid #fff;
    cursor: pointer;
}
.selectedFilterLabel {
    background-color: rgb(162,162,162);
    color: #fff;
    border-left: 1px solid #fff;
    cursor: pointer;
}