.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.form-control.required-error {
  border:1px solid red
}
.icon-scrol-top {width: 40px;height: 40px;position: fixed;right: 20px;bottom: 20px;z-index: 100;cursor: pointer;border-radius: 50%;border: 1px solid #a1a1a1;}
.icon-scrol-top img {width: 100%;}

.flexslider2.page-inner.bannerPage {height:290px}
.bannerPage .page-title {margin-top: 150px}

.flexslider .slides > li {display: block;background-repeat: no-repeat;background-size: 100% auto}
.flexslider .slides > li.overlay:before {background: rgba(255, 255, 255, 0);animation: slider-animation 15s infinite;background-repeat: no-repeat}
.probootstrap-header{position: fixed;}